var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"candidate-alert"},[_c('div',[_c('CRow',{staticClass:"mt-1"},[_c('CCol',{attrs:{"md":"10"}}),_c('CCol',{staticClass:"mb-1",attrs:{"md":"2"}},[_c('Select',{attrs:{"name":"cv_request","value":_vm.alert1['cv_request'],"options":_vm.options && _vm.options['action_status']
              ? _vm.options['action_status']
              : [],"taggable":false,"multiple":false,"clearable":false},on:{"change":_vm.handleChangeSelect}})],1)],1)],1),_c('div',{staticClass:"documentScrolling document-table position-relative"},[_c('CDataTable',{staticClass:"p-2",attrs:{"striped":false,"bordered":false,"small":true,"items":_vm.isPending ? _vm.pendingCVRequestItems : _vm.completedCVRequestItems,"fields":_vm.tableFields,"items-per-page":10,"sorter":false,"tableFilter":false,"pagination":""},scopedSlots:_vm._u([{key:"candidate_display_uid",fn:function({ item }){return [_c('td',[_c('span',{staticClass:"cursor-pointer",staticStyle:{"color":"#2678ce","font-weight":"600"},on:{"click":function($event){return _vm.onCandidateClicked(item.candidate_id)}}},[_vm._v(_vm._s(item.candidate_display_uid))]),_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: 'Click to View Candidate Details',
              placement: 'left',
              appendToBody: true,
            }),expression:"{\n              content: 'Click to View Candidate Details',\n              placement: 'left',\n              appendToBody: true,\n            }"}],staticClass:"ml-2 cursor-pointer",on:{"click":function($event){return _vm.openCandidateDetailsModal(item.candidate_id)}}},[_c('i',{staticClass:"fa-solid fa-circle-info text-primary"})])])]}},{key:"action",fn:function({ item }){return [_c('td',[(item.date_uploaded && !item.close)?_c('CButton',{staticClass:"px-2 py-1",staticStyle:{"font-size":"11px"},attrs:{"type":"button","color":"primary"},on:{"click":function($event){return _vm.updateAgencyCVTransaction(item)}}},[_vm._v("Mark Complete")]):_vm._e()],1)]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }