<template>
  <div class="candidate-alert">
    <div>
      <CRow class="mt-1">
        <CCol md="10"> </CCol>
        <CCol md="2" class="mb-1">
          <Select
            name="cv_request"
            :value="alert1['cv_request']"
            @change="handleChangeSelect"
            :options="
              options && options['action_status']
                ? options['action_status']
                : []
            "
            :taggable="false"
            :multiple="false"
            :clearable="false"
          />
        </CCol>
      </CRow>
    </div>
    <div class="documentScrolling document-table position-relative">
      <CDataTable
        :striped="false"
        :bordered="false"
        :small="true"
        :items="isPending ? pendingCVRequestItems : completedCVRequestItems"
        :fields="tableFields"
        :items-per-page="10"
        :sorter="false"
        :tableFilter="false"
        pagination
        class="p-2"
      >
        <template #candidate_display_uid="{ item }">
          <td>
            <span
              style="color: #2678ce; font-weight: 600"
              class="cursor-pointer"
              @click="onCandidateClicked(item.candidate_id)"
              >{{ item.candidate_display_uid }}</span
            >
            <span
              class="ml-2 cursor-pointer"
              v-c-tooltip="{
                content: 'Click to View Candidate Details',
                placement: 'left',
                appendToBody: true,
              }"
              @click="openCandidateDetailsModal(item.candidate_id)"
              ><i class="fa-solid fa-circle-info text-primary"></i
            ></span>
          </td>
        </template>
        <template #action="{ item }">
          <td>
            <CButton
              v-if="item.date_uploaded && !item.close"
              type="button"
              color="primary"
              class="px-2 py-1"
              style="font-size: 11px;"
              @click="updateAgencyCVTransaction(item)"
              >Mark Complete</CButton
            >
          </td>
        </template>
      </CDataTable>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import { mapGetters, mapActions } from "vuex";
import m from "moment";
export default {
  props: {
    pendingCVRequest: {
      type: Array,
      default: [],
    },
    completedCVRequest: {
      type: Array,
      default: [],
    },
  },
  components: {
    Select,
  },
  data() {
    return {
      alert1: {
        cv_request: { code: "pending", label: "Pending" },
      },
      jobSupplierFields: [
        { key: "requester_user_name", label: "Requested By", _style:"width:20%" },
        { key: "requested_date", label: "Request Date", _style:"width:10%" },
        { key: "candidate_display_uid", label: "Candidate ID", _style:"width:10%" },
        { key: "candidate_name", label: "Candidate Name", _style:"width:25%" },
        { key: "uploader_user_name", label: "Uploaded By", _style:"width:25%" },
        { key: "uploaded_date", label: "Upload Date", _style:"width:10%" },
        { key: "status", label: "Status", _style:"width:10%" },
        { key: "action", label: "Action", _style:"width:20%" },
      ],
      mainAgencyFields: [
        { key: "org_name", label: "Organisation Name", _style:"width:20%" },
        { key: "requester_user_name", label: "Requested By", _style:"width:20%" },
        { key: "requested_date", label: "Request Date", _style:"width:10%" },
        { key: "candidate_display_uid", label: "Candidate ID", _style:"width:10%" },
        { key: "candidate_name", label: "Candidate Name", _style:"width:20%" },
        { key: "uploaded_date", label: "Upload Date", _style:"width:10%" },
        { key: "status", label: "Status", _style:"width:10%" },
      ],
    };
  },
  computed: {
    ...mapGetters(["isUKMainAgency", "isJobSupplierFromAccessToken", "getOrgIDFromAccessToken"]),
    options() {
      return {
        action_status: [
          { code: "pending", label: "Pending" },
          { code: "completed", label: "Completed" },
        ],
      };
    },
    tableFields() {
      return this.isUKMainAgency
        ? this.mainAgencyFields
        : this.jobSupplierFields;
    },
    pendingCVRequestItems() {
      return this.pendingCVRequest.map((v) => ({
        ...v,
        requested_date: m(v?.date_requested).format("DD-MM-YYYY"),
        uploaded_date: v?.date_uploaded
          ? m(v?.date_uploaded).format("DD-MM-YYYY")
          : "--",
        status: this.isUKMainAgency 
          ? (v?.date_uploaded ? "Completed" : "Pending") 
          : (v?.date_uploaded && v?.close ? "Completed" : "Pending"),
        requester_user_name: v?.requester_user_name || "--",
        uploader_user_name: v?.uploader_user_name || "--",
      }));
    },
    completedCVRequestItems() {
      return this.completedCVRequest.map((v) => ({
        ...v,
        requested_date: m(v?.date_requested).format("DD-MM-YYYY"),
        uploaded_date: v?.date_uploaded
          ? m(v?.date_uploaded).format("DD-MM-YYYY")
          : "--",
        status: this.isUKMainAgency 
          ? (v?.date_uploaded ? "Completed" : "Pending") 
          : (v?.date_uploaded && v?.close ? "Completed" : "Pending"),
        requester_user_name: v?.requester_user_name || "--",
        uploader_user_name: v?.uploader_user_name || "--",
      }));
    },
    isPending() {
      return this.alert1?.cv_request?.code === "pending" || false;
    },
  },
  methods: {
    ...mapActions(["updateAgencyTransaction", "fetchCVRequest"]),
    handleChangeSelect(name, value) {
      Vue.set(this.alert1, name, value);
      this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
      const cv_request =
        value?.code === "pending"
          ? this.pendingCVRequest.length
          : this.completedCVRequest.length;
      this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
        cv_request,
      });
    },
    onCandidateClicked(candidate_id) {
      this.$emit("toCandidate", candidate_id);
    },
    openCandidateDetailsModal(candidate_uid) {
      this.$emit("openCandidateInfoModal", candidate_uid);
    },
    updateAgencyCVTransaction(item) {
      let finalPayload = {
        req_org_id: item.req_org_id,
        req_user_id: item.req_user_id,
        candidate_id: item.candidate_id,
        date_requested: item.date_requested,
        uploader_user_id: item.uploader_user_id,
        date_uploaded: item.date_uploaded,
        close: true,
      };
      this.updateAgencyTransaction({
        trans_id: item.transaction_id,
        payload: finalPayload,
      }).then((res) => {
      if(this.isJobSupplierFromAccessToken || this.isUKMainAgency){
        let payload = {
          skip:0,
          limit:200
        };
        if(this.isJobSupplierFromAccessToken){
          payload = {
            ...payload,
            req_org_id: this.getOrgIDFromAccessToken,
            order_by: "-date_requested",
          }
        }else if(this.isUKMainAgency){
          payload = {
            ...payload,
            order_by: "-date_requested",
          }
        }
        this.fetchCVRequest(payload).then(res=>{
          if(res){
            this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
              cv_request: this.pendingCVRequest.length,
            });
          }
        });
      }
      });
    }
  },
}
</script>