var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('CRow',{staticClass:"mt-1"},[_c('CCol',{attrs:{"md":"10"}}),_c('CCol',{staticClass:"mb-1",attrs:{"md":"2"}},[_c('Select',{attrs:{"name":"onboard","value":_vm.alert1['onboard'],"options":_vm.options && _vm.options['action_status']
              ? _vm.options['action_status']
              : [],"taggable":false,"multiple":false,"clearable":false},on:{"change":_vm.handleChangeSelect}})],1)],1)],1),_c('div',{staticClass:"documentScrolling document-table"},[_c('CDataTable',{staticClass:"p-2",attrs:{"striped":false,"bordered":false,"small":true,"items":_vm.getInterviewScheduleActionDetails,"fields":_vm.onboardFields,"items-per-page":10,"sorter":false,"tableFilter":false,"pagination":""},scopedSlots:_vm._u([{key:"candidate_name",fn:function({ item }){return [_c('td',[_c('div',{staticClass:"d-flex text-nowrap"},[_c('span',{staticClass:"circle",style:(`background-color: ${item.circle_color} !important`)}),_c('span',{staticClass:"ml-1"},[_c('span',{staticClass:"clickable-action",staticStyle:{"color":"#2678ce","font-weight":"600"},on:{"click":function($event){return _vm.onCandidateClicked(item.candidate_id)}}},[_c('span',[_vm._v(_vm._s(item.candidate_name))]),_c('span',{staticClass:"mx-1"},[_vm._v("|")]),_c('span',[_vm._v(_vm._s(item.candidate_display_uid))])]),_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: 'Click to View Candidate Details',
                  placement: 'left',
                  appendToBody: true,
                }),expression:"{\n                  content: 'Click to View Candidate Details',\n                  placement: 'left',\n                  appendToBody: true,\n                }"}],staticClass:"ml-2 cursor-pointer",on:{"click":function($event){return _vm.openCandidateDetailsModal(item.candidate_id)}}},[_c('i',{staticClass:"fa-solid fa-circle-info text-primary"})])])])])]}},{key:"job_title",fn:function({ item }){return [_c('td',[_c('div',{staticClass:"d-flex"},[_c('span',[_c('span',{staticClass:"clickable-action",staticStyle:{"color":"#2678ce","font-weight":"600"},on:{"click":function($event){return _vm.onJobClicked(item.job_id)}}},[_c('span',[_vm._v(_vm._s(item.job_title))]),_c('span',{staticClass:"mx-1"},[_vm._v("| ")]),_c('span',[_vm._v(_vm._s(item.job_display_uid))])]),_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: 'Click to View Job Details',
                  placement: 'left',
                  appendToBody: true,
                }),expression:"{\n                  content: 'Click to View Job Details',\n                  placement: 'left',\n                  appendToBody: true,\n                }"}],staticClass:"ml-1 cursor-pointer",on:{"click":function($event){return _vm.openJobDetailsModal(item.job_id)}}},[_c('i',{staticClass:"fa-solid fa-circle-info text-primary"})])])]),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.job_organisation_name ? `[${item.job_organisation_name}]` : ""))])])]}},{key:"no-items-view",fn:function(){return [_c('h5',{staticClass:"text-center",style:('color:')},[_vm._v(" No Data Found "),_c('i',{staticClass:"fas fa-ban",style:({ color: '#e55353' })})])]},proxy:true},{key:"action",fn:function({ item, index }){return [_c('td',{staticClass:"py-2"},[_c('div',{staticClass:"d-flex"},[_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapseIndex === index),expression:"collapseIndex === index"},{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: 'Collapse all Transactions!',
                placement: 'left',
                appendToBody: true,
              }),expression:"{\n                content: 'Collapse all Transactions!',\n                placement: 'left',\n                appendToBody: true,\n              }"}],staticClass:"mr-1 btn-outline-primary btn-sm",attrs:{"square":"","name":"candidate-onboard-tab","id":`cand-onboard-tab-collapse-cand-${item.candidate_uid}-job-${item.job_id}`},on:{"click":function($event){return _vm.onToggleClick(item, index)}}},[_c('i',{staticClass:"fa fa-minus"})]),_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapseIndex !== index),expression:"collapseIndex !== index"},{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: 'List all Transactions!',
                placement: 'left',
                appendToBody: true,
              }),expression:"{\n                content: 'List all Transactions!',\n                placement: 'left',\n                appendToBody: true,\n              }"}],staticClass:"mr-1 btn-outline-primary btn-sm position-relative",attrs:{"square":"","name":"candidate-onboard-tab","id":`cand-onboard-tab-list-cand-${item.candidate_uid}-job-${item.job_id}`},on:{"click":function($event){return _vm.onToggleClick(item, index)}}},[_c('i',{staticClass:"fa fa-plus"}),(_vm.isActionableItemHave(item))?_c('span',{staticClass:"dot-badge"}):_vm._e()])],1)])]}},{key:"docs",fn:function({ item }){return [_c('td',{staticClass:"py-2"},[_c('a',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ content: 'Documents', placement: 'left' }),expression:"{ content: 'Documents', placement: 'left' }"}],staticClass:"btn d-inline"},[_c('img',{attrs:{"src":item.onboard_document_submitted
                  ? _vm.checklistGreen
                  : _vm.checklistRed,"width":"25","height":"25"},on:{"click":function($event){return _vm.openCheckList(item)}}})])])]}},{key:"comments",fn:function({ item }){return [_c('td',[_c('span',[_vm._v(_vm._s(item.comments)+" ")])])]}},{key:"details",fn:function({ item, index }){return [(_vm.collapseIndex === index)?_c('CCollapse',{staticClass:"p-2 border selected-row",attrs:{"show":_vm.collapseIndex === index,"duration":_vm.collapseDuration}},[_c('h5',{staticClass:"p-2 text-primary"},[_vm._v(" Job ID: "+_vm._s(item.job_display_uid)+" "),_c('span',{staticStyle:{"color":"black"}},[_vm._v("|")]),_vm._v(" Job Title: "+_vm._s(item.job_title)+" "),_c('span',{staticStyle:{"color":"black"}},[_vm._v("|")]),_vm._v(" Transactions: "+_vm._s(_vm.getDashboardTabCount.accordion)+" ")]),(_vm.isTransactionFetch)?_c('CSpinner',{staticClass:"mx-5",staticStyle:{"width":"2rem","height":"2rem"},attrs:{"color":"primary"}}):_c('AccordionTransaction',{attrs:{"accordionFields":_vm.jobAccordionOnboardFields,"showThreeDots":true},on:{"completeClicked":function($event){return _vm.$emit('completeClicked', item)},"viewDocument":function($event){return _vm.$emit('openCheckList',item, 1, 'licensing')},"viewDocumentDetails":function($event){return _vm.$emit('openCheckList',item, 1, 'licensing')},"iniateJoining":function($event){return _vm.$emit('iniateJoining', item)},"documentsClicked":function($event){return _vm.$emit('openCheckList',item, 1, 'licensing')},"actionModalEvent":_vm.actionModalEvent}})],1):_vm._e()]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }