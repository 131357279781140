<template>
  <div>
    <div>
      <CRow class="mt-1">
        <CCol md="10">
          <CRow>
            <CCol md="12" style="margin-top: 5px; margin-left: 10px">
              <span style="color: red; font-weight: 500">
                Approvals:
                {{ getApprovedAndCancelledSubmission["approved_count"] }},
                Cancelled:
                {{ getApprovedAndCancelledSubmission["cancelled_count"] }}, On
                Hold: {{ getApprovedAndCancelledSubmission["hold_count"] }}
              </span>
            </CCol>
          </CRow>
        </CCol>
        <CCol md="2" class="mb-1">
          <Select
            name="submission"
            :value="alert1['submission']"
            @change="handleChangeSelect"
            :options="
              options && options['action_status']
                ? options['action_status']
                : []
            "
            :taggable="false"
            :multiple="false"
            :clearable="false"
          />
        </CCol>
      </CRow>
    </div>
    <div class="documentScrolling document-table">
      <CDataTable
        :striped="false"
        :bordered="false"
        :small="true"
        :items="getSubmisionAction"
        :fields="candiateFields"
        :items-per-page="10"
        :sorter="false"
        :tableFilter="false"
        pagination
        class="p-2"
      >
        <template #candidate_name="{ item }">
          <td>
            <div class="d-flex text-nowrap">
              <span
                class="circle"
                :style="`background-color: ${item.circle_color} !important`"
              ></span>
              <span class="ml-1">
              <span
                style="color: #2678ce; font-weight: 600"
                class="clickable-action"
                @click="onCandidateClicked(item.candidate_id)"
              >
                <span>{{ item.candidate_name }}</span>
                <span class="mx-1">|</span>
                <span>{{ item.candidate_display_uid }}</span>
              </span>
              <span
                class="ml-2 cursor-pointer"
                v-c-tooltip="{
                  content: 'Click to View Candidate Details',
                  placement: 'left',
                  appendToBody: true,
                }"
                @click="openCandidateDetailsModal(item.candidate_id)"
                ><i class="fa-solid fa-circle-info text-primary"></i
              ></span>
              </span>
            </div>
          </td>
        </template>
        <!-- <template #candidate_id="{ item }">
          <td style="padding: 0">
            <tr>
              <td width="25%" style="border: none !important">
                <span
                  class="circle"
                  :style="`background-color: ${item.circle_color} !important`"
                ></span>
              </td>
              <td width="75%" style="border: none !important">
                <span
                  style="color: #2678ce; font-weight: 600"
                  class="clickable-action"
                  @click="onCandidateClicked(item.candidate_id)"
                  >{{ item.candidate_display_uid }}</span
                >
                <span
                  class="ml-2 cursor-pointer"
                  v-c-tooltip="{
                    content: 'Click to View Candidate Details',
                    placement: 'left',
                    appendToBody: true,
                  }"
                  @click="openCandidateDetailsModal(item.candidate_id)"
                  ><i class="fa-solid fa-circle-info text-primary"></i
                ></span>
              </td>
            </tr>
          </td>
        </template> -->
        <!-- <template #job_id="{ item }">
          <td>
            <span
              style="color: #2678ce; font-weight: 600"
              class="clickable-action"
              @click="onJobClicked(item.job_id)"
              >{{ item.job_display_uid }}</span
            >
            <span
              class="ml-1 cursor-pointer"
              v-c-tooltip="{
                content: 'Click to View Job Details',
                placement: 'left',
                appendToBody: true,
              }"
              @click="openJobDetailsModal(item.job_id)"
              ><i class="fa-solid fa-circle-info text-primary"></i
            ></span>
          </td>
        </template> -->
        <template #job_title="{ item }">
          <td>
            <div class="d-flex">
              <span>
              <span
                style="color: #2678ce; font-weight: 600"
                class="clickable-action"
                @click="onJobClicked(item.job_id)"
              >
                <span>{{ item.job_title }}</span>
                <span class="mx-1">| </span>
                <span>{{ item.job_display_uid }}</span>
              </span>
              <span
                class="ml-1 cursor-pointer"
                v-c-tooltip="{
                  content: 'Click to View Job Details',
                  placement: 'left',
                  appendToBody: true,
                }"
                @click="openJobDetailsModal(item.job_id)"
                ><i class="fa-solid fa-circle-info text-primary"></i
              ></span>
              </span>
            </div>
            <span class="text-nowrap">{{
              item.job_organisation_name
                ? `[${item.job_organisation_name}]`
                : ""
            }}</span>
          </td>
        </template>
        <template #no-items-view>
          <h5 class="text-center" :style="'color:'">
            No Data Found
            <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
          </h5>
        </template>
        <template #action="{ item, index }">
          <td class="py-2">
            <div class="d-flex">
              <CButton
                v-show="collapseIndex === index"
                color="primary"
                square
                variant="outline"
                size="sm"
                class="mr-1"
                @click="onToggleClick(item, index)"
                v-c-tooltip="{
                  content: 'Collapse all Transactions!',
                  placement: 'left',
                  appendToBody: true,
                }"
              >
                <i class="fa fa-minus"></i>
              </CButton>
              <CButton
                v-show="collapseIndex !== index"
                color="primary"
                square
                variant="outline"
                size="sm"
                class="mr-1 position-relative"
                @click="onToggleClick(item, index)"
                v-c-tooltip="{
                  content: 'List all Transactions!',
                  placement: 'left',
                  appendToBody: true,
                }"
              >
                <i class="fa fa-plus"></i>
                <span
                  class="dot-badge"
                  v-if="isActionableItemHave(item) || item.days < 0"
                ></span>
              </CButton>
            </div>
          </td>
        </template>
        <template #details="{ item, index }">
          <CCollapse
            v-if="collapseIndex === index"
            :show="collapseIndex === index"
            :duration="collapseDuration"
            class="p-2 border selected-row"
          >
            <h5 class="p-2 text-primary">
              Job ID: {{ item.job_display_uid }}
              <span style="color: black">|</span> Job Title:
              {{ item.job_title }}
              <span style="color: black">|</span> Transactions:
              {{ getDashboardTabCount.accordion }}
            </h5>
            <CSpinner
              v-if="isTransactionFetch"
              color="primary"
              class="mx-5"
              style="width: 2rem; height: 2rem"
            />
            <AccordionTransaction
              v-else
              :accordionFields="jobAccordionSubmittedFields"
              @resubmitCandidateClick="$emit('resubmitCandidateClick', item)"
              @completeClicked="$emit('completeClicked', item)"
              @reinstateClicked="$emit('reinstateClicked', item)"
              @vaultstateClicked="$emit('vaultstateClicked', item)"
              @callForActions="callForActions()"
              :showUpdateExpiry="true"
              :showThreeDots="true"
              @actionModalEvent="actionModalEvent"
            />
          </CCollapse>
        </template>
        <template #docs="{ item }">
          <td class="py-2">
            <a
              class="btn d-inline"
              v-c-tooltip="{ content: 'Documents', placement: 'left' }"
            >
              <img
                :src="checklistGreen"
                width="25"
                height="25"
                @click="openCheckList(item)"
              />
            </a>
          </td>
        </template>
      </CDataTable>
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import {
  getRAGColorsCircle,
  actionableItem,
  isObject,
  isEmptyObjectCheck,
} from "@/helpers/helper";

import AccordionTransaction from "@/containers/Dashboard/RecruiterDashBoard/JobCandidateStatus/AccordionTransaction";
import checklistGreen from "/public/img/checklist-green.svg";
import checklistRed from "/public/img/checklist-red.svg";

export default {
  props: {
    collapseIndex: {
      type: Number,
      default: () => null,
    },
    collapseDuration: {
      type: Number,
      default: () => 0,
    },
    isTransactionFetch: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    Select,
    AccordionTransaction,
  },
  data() {
    return {
      alert1: {
        job_approval: { code: "pending", label: "Pending" },
        submission: { code: "pending", label: "Pending" },
        interview: { code: "pending", label: "Pending" },
        scheduled: { code: "pending", label: "Pending" },
      },
      jobAccordionSubmittedFields: [
        { key: "initiated_date", label: "Date", _style: "width: 20%" },
        { key: "requested_by", label: "Name", _style: "width: 15%" },
        // { key: "approved_by", label: "Approval By", _style: "width: 20%" },
        { key: "display_status", label: "Status", _style: "width: 15%" },
        { key: "comments", label: "Comments", _style: "width: 25%" },
        { key: "action", label: "Action", _style: "width: 15%" },
      ],
      candiateFields: [
        // { label: "Candidate ID", key: "candidate_id", _style: "width: 8%" },
        {
          label: "Candidate",
          key: "candidate_name",
          _style: "width: 15%",
        },
        // { label: "Job ID", key: "job_id", _style: "width: 5%" },
        { label: "Job", key: "job_title", _style: "width: 18%" },
        {
          key: "days_for_approval",
          label: "Days for Approval",
          _style: "width: 5%",
        },
        {
          label: "Joining Date",
          key: "joining_date",
          _style: "width: 8%",
        },
        { key: "approved_by", label: "Approval By", _style: "width: 8%" },
        { label: "Status", key: "status", _style: "width: 10%" },
        { label: "Comments", key: "comments", _style: "width: 15%" },
        { key: "action", label: "Action", _style: "width:5%" },
        { key: "docs", label: "Check List", _style: "width:5%" },
      ],
      checklistGreen,
      checklistRed,
    };
  },
  computed: {
    ...mapGetters([
      "getSubmissionAction",
      "getDashboardTabCount",
      "isCandidateSupplierFromAccessToken",
    ]),
    options() {
      return {
        action_status: [
          { code: "pending", label: "Pending" },
          { code: "completed", label: "Completed" },
        ],
      };
    },
    getSubmisionAction() {
      return (
        this.getSubmissionAction
          .map((v) => ({
            ...v,
            candidate_id: v?.candidate_uid,
            candidate_display_uid: v?.candidate_display_uid,
            candidate_name: v?.candidate?.full_name,
            job_title: v?.job?.job_title,
            job_organisation_name: v?.job?.organisation_name,
            initiated_date: this.formatDate(v?.initiated_date),
            // status: v?.completed_date === null ? "Pending" : "Completed",
            status: `${v?.status} (${v?.sub_status})`,
            comments: v?.comments || "--",
            // days_for_approval: [53].includes(v?.sub_status_id) ? `${getDaysFromDate(v?.expiry_date)} Day(s)` : "--",
            days_for_approval: v?.display_text_days || "--",
            requested_by: v?.initiated_by_user_name
              ? v?.initiated_by_user_name
              : "--",
            approved_by: [53, 44, 47, null].includes(v?.sub_status_id)
              ? v?.initiated_by_user_name
                ? v?.initiated_by_user_name
                : "--"
              : "--",
            /*  approved_by: [53,null].includes(v?.sub_status_id)
              ? v?.assigned_to_users_name
                ? v?.assigned_to_users_name
                : "--"
              : "--",*/
            // days: [53].includes(v?.sub_status_id) ? getDaysFromDate(v?.expiry_date) : getDaysFromDate(v?.initiated_date),
            // _classes: [44, 53].includes(v?.sub_status_id) ? this.getRAGColors(v?.days) : "",
            circle_color: [44, 53].includes(v?.sub_status_id)
              ? getRAGColorsCircle(v?.days)
              : "",
            joining_date: this.formatDate(v?.joining_date),
          }))
          .filter((val) => {
            const isNull =
              this.alert1["submission"]?.code === "pending" ? true : false;
            // if (isNull)
            // // 53 - Awaiting Feedback, 44 - On Hold
            //   return [53, 44].includes(val?.sub_status_id) && val?.completed_date === null;
            // else
            // // 47 - Disapproved, 48 - Withdrawn, 49 - Reserve, 50 - Approved for Interview
            //   return [47, 48, 49, 50].includes(val?.sub_status_id);
            if (isNull) return !val?.reviewed;
            else return val?.reviewed;
          })
          .sort((a, b) => {
            if ([53].includes(a?.sub_status_id) && b.days >= 0)
              return a.days > b.days ? 1 : b.days > a.days ? -1 : 0;
            return a.action_detail_id - b.action_detail_id;
          }) || []
      );
    },
    getApprovedAndCancelledSubmission() {
      let approved_count = 0,
        cancelled_count = 0,
        hold_count = 0;
      this.getSubmissionAction
        .filter((val) => {
          const isNull =
            this.alert1["submission"]?.code === "pending" ? true : false;
          if (isNull)
            return [44, 47, 50].includes(val?.sub_status_id) && !val.reviewed;
          else return [44, 47, 50].includes(val?.sub_status_id) && val.reviewed;
        })
        .map((v) => ({
          approved_count:
            v?.sub_status_id == 50
              ? (approved_count += 1)
              : (approved_count += 0),
          cancelled_count:
            v?.sub_status_id == 47
              ? (cancelled_count += 1)
              : (cancelled_count += 0),
          hold_count:
            v?.sub_status_id == 44 ? (hold_count += 1) : (hold_count += 0),
        }));
      return { approved_count, cancelled_count, hold_count };
    },
  },
  mounted() {
    this.callForActions();
  },
  methods: {
    ...mapActions(["fetchSubmissionActionDetails"]),
    // getPreOfferDocuments(item) {
    //   this.$emit("preOfferViewDocumentDetails", item);
    // },
    openCheckList(item){
      this.$emit("openCheckList",item);
    },
    actionModalEvent(data) {
      this.$emit("actionModalEvent", data);
    },
    openCandidateDetailsModal(candidate_uid) {
      this.$emit("openCandidateInfoModal", candidate_uid);
    },
    openJobDetailsModal(job_id) {
      this.$emit("openJobInfoModal", job_id);
    },
    callForActions() {
      this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
      let appendAction = [];
      appendAction = [...appendAction, this.fetchSubmissionActionDetails()];

      Promise.all(appendAction).then((res) => {
        this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
          submission: this.getSubmisionAction.length,
        });
      });
    },
    onToggleClick(item, index) {
      this.$emit("toggleClick", item, index, true, "submission");
    },
    onJobClicked(job_id) {
      this.$emit("toJob", job_id);
    },
    onCandidateClicked(candidate_id) {
      this.$emit("toCandidate", candidate_id);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.alert1, name, value);
      this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
      this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
        submission: this.getSubmisionAction.length,
      });
    },
    formatDate(date) {
      if (date) return moment(date).format("DD MMM YYYY");
      return "--";
    },
    isActionableItemHave({ status_id, sub_status_id }) {
      if (this.isCandidateSupplierFromAccessToken) return false;
      const res = _.find(actionableItem, { status_id, sub_status_id });
      return (isObject(res) && !isEmptyObjectCheck(res)) || false;
    },
  },
};
</script>
<style lang="scss" scoped>
.nowrap {
  white-space: nowrap;
}
table {
  border: 1px solid #e3e3e3;
}
thead {
  background-color: #fafafa;
}
td {
  color: #777777;
}
.clickable-row {
  cursor: pointer;
}
.clickable-row:hover {
  background-color: #f3f3f3;
}
.clickable-action {
  cursor: pointer;
}
.clickable-action:hover {
  text-decoration: underline;
}
</style>