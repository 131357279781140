var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"candidate-alert position-relative"},[_c('div',[_c('CRow',{staticClass:"mt-1"},[_c('CCol',{attrs:{"md":"10"}},[_c('CRow',[_c('CCol',{staticStyle:{"margin-top":"5px","margin-left":"10px"},attrs:{"md":"12"}},[_c('span',{staticStyle:{"color":"red","font-weight":"500"}},[_vm._v(" Approvals: "+_vm._s(_vm.getApprovedAndCancelledJobs["approved_count"])+", Cancelled: "+_vm._s(_vm.getApprovedAndCancelledJobs["cancelled_count"])+" ")])])],1)],1),_c('CCol',{staticClass:"mb-1",attrs:{"md":"2"}},[_c('Select',{attrs:{"name":"job_approval","value":_vm.alert1['job_approval'],"options":_vm.options && _vm.options['action_status']
              ? _vm.options['action_status']
              : [],"taggable":false,"multiple":false,"clearable":false},on:{"change":_vm.handleChangeSelect}})],1)],1)],1),_c('div',{staticClass:"documentScrolling document-table position-relative"},[_c('CDataTable',{staticClass:"p-2",attrs:{"striped":false,"bordered":false,"small":true,"items":_vm.getJobApprovalActionDetails,"fields":!_vm.isUKMainAgency ? _vm.jobFields : _vm.jobFieldsForPrtmp,"items-per-page":10,"sorter":false,"tableFilter":false,"pagination":""},scopedSlots:_vm._u([{key:"job_id",fn:function({ item }){return [_c('td',{staticStyle:{"padding":"0"}},[_c('tr',[_c('td',{staticClass:"px-1",staticStyle:{"border":"none !important"},attrs:{"width":"25%"}},[_c('span',{staticClass:"circle",style:(`background-color: ${item.circle_color} !important`)})]),_c('td',{staticClass:"px-1",staticStyle:{"border":"none !important"},attrs:{"width":"75%"}},[_c('span',{staticClass:"clickable-action",staticStyle:{"color":"#2678ce","font-weight":"600"},on:{"click":function($event){return _vm.onJobClicked(item.job_id)}}},[_vm._v(_vm._s(item.job_display_uid))]),_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: 'Click to View Job Details',
                  placement: 'left',
                  appendToBody: true,
                }),expression:"{\n                  content: 'Click to View Job Details',\n                  placement: 'left',\n                  appendToBody: true,\n                }"}],staticClass:"ml-2 cursor-pointer",on:{"click":function($event){return _vm.openJobDetailsModal(item.job_id)}}},[_c('i',{staticClass:"fa-solid fa-circle-info text-primary"})])])])])]}},{key:"job_title",fn:function({ item }){return [_c('td',[_vm._v(" "+_vm._s(item.job_title)+" "),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.job_organisation_name ? `[${item.job_organisation_name}]` : ""))])])]}},{key:"no-items-view",fn:function(){return [_c('h5',{staticClass:"text-center",style:('color:')},[_vm._v(" No Data Found "),_c('i',{staticClass:"fas fa-ban",style:({ color: '#e55353' })})])]},proxy:true},{key:"action",fn:function({ item, index }){return [_c('td',{staticClass:"py-2"},[_c('div',{staticClass:"d-flex"},[_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapseIndex === index),expression:"collapseIndex === index"},{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: 'Collapse all Transactions!',
                placement: 'left',
                appendToBody: true,
              }),expression:"{\n                content: 'Collapse all Transactions!',\n                placement: 'left',\n                appendToBody: true,\n              }"}],staticClass:"mr-1 btn-outline-primary btn-sm",attrs:{"name":"collapse-transaction-btn","id":`collapse-transaction-btn-${item.job_id}`,"square":""},on:{"click":function($event){return _vm.onToggleClick(item, index)}}},[_c('i',{staticClass:"fa fa-minus"})]),_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapseIndex !== index),expression:"collapseIndex !== index"},{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: 'List all Transactions!',
                placement: 'left',
                appendToBody: true,
              }),expression:"{\n                content: 'List all Transactions!',\n                placement: 'left',\n                appendToBody: true,\n              }"}],staticClass:"mr-1 btn-outline-primary btn-sm position-relative",attrs:{"name":"list-transaction-btn","id":`list-transaction-btn-${item.job_id}`,"square":""},on:{"click":function($event){return _vm.onToggleClick(item, index)}}},[_c('i',{staticClass:"fa fa-plus"}),(_vm.isActionableItemHave(item))?_c('span',{staticClass:"dot-badge"}):_vm._e()])],1)])]}},{key:"details",fn:function({ item, index }){return [(_vm.collapseIndex === index)?_c('CCollapse',{staticClass:"p-2 border selected-row",attrs:{"show":_vm.collapseIndex === index,"duration":_vm.collapseDuration}},[_c('h5',{staticClass:"p-2 text-primary"},[_vm._v(" Job ID: "+_vm._s(item.job_display_uid)+" "),_c('span',{staticStyle:{"color":"black"}},[_vm._v("|")]),_vm._v(" Job Title: "+_vm._s(item.job_title)+" "),_c('span',{staticStyle:{"color":"black"}},[_vm._v("|")]),_vm._v(" Transactions: "+_vm._s(_vm.getDashboardTabCount.accordion)+" ")]),(_vm.isTransactionFetch)?_c('CSpinner',{staticClass:"mx-5",staticStyle:{"width":"2rem","height":"2rem"},attrs:{"color":"primary"}}):_c('AccordionTransaction',{attrs:{"accordionFields":_vm.jobAccordionFields},on:{"editJobClick":function($event){return _vm.$emit('editJobClick', item)},"completeClicked":function($event){return _vm.$emit('completeClicked', item)},"toJobBoard":function($event){return _vm.$emit('toJobBoard', item)},"jobReapprovalstateClicked":function($event){return _vm.$emit('jobReapprovalstateClicked', item)}}})],1):_vm._e()]}}])}),(_vm.isJobSupplierFromAccessToken)?_c('div',{staticClass:"cover position-absolute d-flex justify-content-center align-items-center"},[_vm._m(0)]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"text-center text-danger mb-4 p-3 border border-danger rounded-lg font-weight-bold"},[_vm._v("Full TalentFind License Required"),_c('span',{staticClass:"d-block"},[_vm._v("contact@talentfindsolutions.com")])])
}]

export { render, staticRenderFns }