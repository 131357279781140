<template>
  <div class="joining-confirmation" name="joining-confirmation-modal">
    <CModal
      color="primary"
      v-if="!showDateModal"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
    >
      <template #header>
        <h6 class="modal-title">Joining Confirmation</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Cancel</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="showDateModal = true"
          >Confirm</CButton
        >
      </template>
      <div>Please confirm this candidate is being moved to 'Joined'</div>
    </CModal>
    <CModal
      color="primary"
      :show.sync="showDateModal"
      :close-on-backdrop="false"
    >
      <template #header>
        <h6 class="modal-title">Joining Date</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Cancel</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit"
          >Submit</CButton
        >
      </template>
      <div>
        <ValidationObserver ref="joining_date_form" v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            name="joining_date_form"
          >
            <CRow>
              <label class="col-lg-6 required">Joining Date</label>
              <CCol md="6">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <DatePicker
                    name="joining_date"
                    :value="joining_date"
                    @input="handleDateChange"
                    format="DD-MM-YYYY"
                    :showFromToday="true"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import DatePicker from "@/components/reusable/Fields/DatePicker";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapActions } from "vuex";
import moment from "moment";
extend("required", { ...required, message: "This field is required" });
export default {
  name: "joining-confirmation",
  props: {
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      showDateModal: false,
      showConfirmationModal: true,
      joining_date: null,
    };
  },
  methods: {
    ...mapActions(["showToast"]),
    handleDateChange(name, value) {
      this.joining_date = value;
    },
    async onSubmit() {
      const isValid = await this.$refs.joining_date_form.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      const joining_date = this.joining_date
        ? moment(this.joining_date).format("YYYY-MM-DD 00:00:00")
        : null;
      this.modalCallBack(true, {joining_date});
    },
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
  },
};
</script>