<template>
  <div class="candidate-alert position-relative">
    <div>
      <CRow class="mt-1">
        <CCol md="10">
          <CRow>
            <CCol md="12" style="margin-top: 5px; margin-left: 10px">
              <span style="color: red; font-weight: 500;">
                Approvals: {{ getApprovedAndCancelledJobs["approved_count"] }},
                Cancelled: {{ getApprovedAndCancelledJobs["cancelled_count"] }}
              </span>
            </CCol>
          </CRow>
        </CCol>
        <CCol md="2" class="mb-1">
          <Select
            name="job_approval"
            :value="alert1['job_approval']"
            @change="handleChangeSelect"
            :options="
              options && options['action_status']
                ? options['action_status']
                : []
            "
            :taggable="false"
            :multiple="false"
            :clearable="false"
          />
        </CCol>
      </CRow>
    </div>
    <div class="documentScrolling document-table position-relative">
      <CDataTable
        :striped="false"
        :bordered="false"
        :small="true"
        :items="getJobApprovalActionDetails"
        :fields="!isUKMainAgency ? jobFields : jobFieldsForPrtmp"
        :items-per-page="10"
        :sorter="false"
        :tableFilter="false"
        pagination
        class="p-2"
      >
        <template #job_id="{ item }">
          <td style="padding: 0">
            <tr>
              <td width="25%" style="border: none !important" class="px-1">
                <span
                  class="circle"
                  :style="`background-color: ${item.circle_color} !important`"
                ></span>
              </td>
              <td width="75%" style="border: none !important" class="px-1">
                <span
                  style="color: #2678ce; font-weight: 600"
                  class="clickable-action"
                  @click="onJobClicked(item.job_id)"
                  >{{ item.job_display_uid }}</span
                >
                <span
                  class="ml-2 cursor-pointer"
                  v-c-tooltip="{
                    content: 'Click to View Job Details',
                    placement: 'left',
                    appendToBody: true,
                  }"
                  @click="openJobDetailsModal(item.job_id)"
                  ><i class="fa-solid fa-circle-info text-primary"></i
                ></span>
              </td>
            </tr>
          </td>
        </template>
        <template #job_title="{ item }">
          <td>
            {{ item.job_title }}
            <span class="text-nowrap">{{
              item.job_organisation_name
                ? `[${item.job_organisation_name}]`
                : ""
            }}</span>
          </td>
        </template>
        <template #no-items-view>
          <h5 class="text-center" :style="'color:'">
            No Data Found
            <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
          </h5>
        </template>
        <template #action="{ item, index }">
          <td class="py-2">
            <div class="d-flex">
              <CButton
                v-show="collapseIndex === index"
                name="collapse-transaction-btn"
                :id="`collapse-transaction-btn-${item.job_id}`"
                square
                class="mr-1 btn-outline-primary btn-sm"
                @click="onToggleClick(item, index)"
                v-c-tooltip="{
                  content: 'Collapse all Transactions!',
                  placement: 'left',
                  appendToBody: true,
                }"
              >
                <i class="fa fa-minus"></i>
              </CButton>
              <CButton
                v-show="collapseIndex !== index"
                name="list-transaction-btn"
                :id="`list-transaction-btn-${item.job_id}`"
                square
                class="mr-1 btn-outline-primary btn-sm position-relative"
                @click="onToggleClick(item, index)"
                v-c-tooltip="{
                  content: 'List all Transactions!',
                  placement: 'left',
                  appendToBody: true,
                }"
              >
                <i class="fa fa-plus"></i>
                <span
                  class="dot-badge"
                  v-if="isActionableItemHave(item)"
                ></span>
              </CButton>
            </div>
          </td>
        </template>
        <template #details="{ item, index }">
          <CCollapse
            v-if="collapseIndex === index"
            :show="collapseIndex === index"
            :duration="collapseDuration"
            class="p-2 border selected-row"
          >
            <h5 class="p-2 text-primary">
              Job ID: {{ item.job_display_uid }}
              <span style="color: black">|</span> Job Title:
              {{ item.job_title }}
              <span style="color: black">|</span> Transactions:
              {{ getDashboardTabCount.accordion }}
            </h5>
            <CSpinner
              v-if="isTransactionFetch"
              color="primary"
              class="mx-5"
              style="width: 2rem; height: 2rem"
            />
            <AccordionTransaction
              v-else
              :accordionFields="jobAccordionFields"
              @editJobClick="$emit('editJobClick', item)"
              @completeClicked="$emit('completeClicked', item)"
              @toJobBoard="$emit('toJobBoard', item)"
              @jobReapprovalstateClicked="
                $emit('jobReapprovalstateClicked', item)
              "
            />
          </CCollapse>
        </template>
      </CDataTable>
      <div
        class="
          cover
          position-absolute
          d-flex
          justify-content-center
          align-items-center
        "
        v-if="isJobSupplierFromAccessToken"
      >
        <h5 class="text-center text-danger mb-4 p-3 border border-danger rounded-lg font-weight-bold">Full TalentFind License Required<span class="d-block">contact@talentfindsolutions.com</span></h5>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import {
  getRAGColorsCircle,
  actionableItem,
  isObject,
  isEmptyObjectCheck,
} from "@/helpers/helper";

import AccordionTransaction from "@/containers/Dashboard/RecruiterDashBoard/JobCandidateStatus/AccordionTransaction";

export default {
  props: {
    collapseIndex: {
      type: Number,
      default: () => null,
    },
    collapseDuration: {
      type: Number,
      default: () => 0,
    },
    isTransactionFetch: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    Select,
    AccordionTransaction,
  },
  data() {
    return {
      alert1: {
        job_approval: { code: "pending", label: "Pending" },
        submission: { code: "pending", label: "Pending" },
        interview: { code: "pending", label: "Pending" },
        scheduled: { code: "pending", label: "Pending" },
      },
      jobFields: [
        { key: "job_id", label: "Job ID", _style: "width: 7%" },
        { key: "job_title", label: "Job Title", _style: "width: 13%" },
        // { key: "requested_by", label: "Requested By", _style: "width: 10%" },
        // {
        //   key: "initiated_date",
        //   label: "Initiated Date",
        //   _style: "width: 10%",
        // },
        {
          key: "days_for_approval",
          label: "Days for Approval",
          _style: "width: 12%",
        },
        { key: "approved_by", label: "Approval By", _style: "width: 18%" },
        { key: "status", label: "Status", _style: "width: 20%" },
        { key: "comments", label: "Comments", _style: "width: 25%" },
        { key: "action", label: "Action", _style: "width:5%" },
      ],
      jobFieldsForPrtmp: [
        { key: "job_id", label: "Job ID", _style: "width: 7%" },
        { key: "job_title", label: "Job Title", _style: "width: 13%" },
        {
          key: "days_for_approval",
          label: "Days for Approval",
          _style: "width: 12%",
        },
        { key: "name", label: "Name", _style: "width: 18%" },
        { key: "organisation", label: "Organisation", _style: "width: 18%" },
        { key: "approved_by", label: "Approval By", _style: "width: 18%" },
        { key: "status", label: "Status", _style: "width: 20%" },
        { key: "comments", label: "Comments", _style: "width: 25%" },
        { key: "action", label: "Action", _style: "width:5%" },
      ],
      jobAccordionFields: [
        { key: "initiated_date", label: "Date", _style: "width: 20%" },
        { key: "requested_by", label: "Name", _style: "width: 20%" },
        // { key: "approved_by", label: "Approval By", _style: "width: 20%" },
        { key: "display_status", label: "Status", _style: "width: 15%" },
        { key: "comments", label: "Comments", _style: "width: 30%" },
        { key: "action", label: "Action", _style: "width: 15%" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getJobApprovalAction",
      "getDashboardTabCount",
      "isUKMainAgency",
      "isJobSupplierFromAccessToken",
    ]),
    options() {
      return {
        action_status: [
          { code: "pending", label: "Pending" },
          { code: "completed", label: "Completed" },
        ],
      };
    },
    getJobApprovalActionDetails() {
      return (
        this.getJobApprovalAction
          .map((v) => ({
            ...v,
            job_title: v?.job?.job_title,
            job_organisation_name: v?.job?.organisation_name,
            initiated_date: this.formatDate(v?.initiated_date),
            // days_for_approval: v?.status_id == 28
            //                   ? v?.sub_status_id == 74 ? "--"
            //                    :`${getDaysFromDate(v?.expiry_date)} Day(s)`
            //                    : "--",
            // days: v?.status_id == 28 ? getDaysFromDate(v?.expiry_date) : "--",
            days_for_approval: v?.display_text_days || "--",
            status:
              v?.status_id == 28 && v?.completed_by == null
                ? `${v?.status} (${v?.sub_status})`
                : "Completed",
            // status: v?.status_id == 28 && v?.completed_by == null ? "Pending" : "Completed",
            comments: v?.comments || "--",
            requested_by: v?.initiated_by_user_name
              ? v?.initiated_by_user_name
              : "--",
            approved_by:
              v?.status_id == 28
                ? v?.assigned_to_users_name
                  ? v?.assigned_to_users_name
                  : "--"
                : v?.status_id == 29
                ? v?.initiated_by_user_name
                  ? v?.initiated_by_user_name
                  : "--"
                : "--",
            name: v?.initiated_by_user_name || "--",
            organisation: v?.organisation_name || "--",
            // _classes: v?.status_id == 28 ? this.getRAGColors(v?.days) : "",
            circle_color: v?.status_id == 28 ? getRAGColorsCircle(v?.days) : "",
          }))
          .filter((val) => {
            const isNull =
              this.alert1["job_approval"]?.code === "pending" ? true : false;
            if (isNull) return !val?.reviewed;
            else return val?.reviewed;
          })
          .sort((a, b) => {
            if (a?.status_id === 28 && b.days >= 0)
              return a.days > b.days ? 1 : b.days > a.days ? -1 : 0;
            return new Date(b.expiry_date) - new Date(a.expiry_date);
          }) || []
      );
    },
    getApprovedAndCancelledJobs() {
      let approved_count = 0,
        cancelled_count = 0;
      this.getJobApprovalAction
        .filter((val) => {
          const isNull =
            this.alert1["job_approval"]?.code === "pending" ? true : false;
          if (isNull)
            return (
              [28, 29].includes(val?.status_id) &&
              [74, null].includes(val?.sub_status_id) &&
              !val.reviewed
            );
          else
            return (
              [28, 29].includes(val?.status_id) &&
              [74, null].includes(val?.sub_status_id) &&
              val.reviewed
            );
        })
        .map((v) => ({
          approved_count:
            v?.status_id == 29 && v?.sub_status_id == null
              ? (approved_count += 1)
              : (approved_count += 0),
          cancelled_count:
            v?.status_id == 28 && v?.sub_status_id == 74
              ? (cancelled_count += 1)
              : (cancelled_count += 0),
        }));
      return { approved_count, cancelled_count };
    },
  },
  mounted() {
    this.callForActions();
  },
  methods: {
    ...mapActions(["fetchJobApprovalAction"]),    
    openJobDetailsModal(job_id) {
      this.$emit("openJobInfoModal", job_id);
    },
    callForActions() {
      let appendAction = [];
      appendAction = [...appendAction, this.fetchJobApprovalAction()];

      Promise.all(appendAction).then((res) => {
        this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
          job_approval: this.getJobApprovalActionDetails.length,
        });
      });
    },
    onToggleClick(item, index) {
      this.$emit("toggleClick", item, index, false, "job_approval");
    },
    onJobClicked(job_id) {
      this.$emit("toJob", job_id);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.alert1, name, value);
      this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
      this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
        job_approval: this.getJobApprovalActionDetails.length,
      });
    },
    formatDate(date) {
      if (date) return moment(date).format("DD MMM YYYY");
      return "--";
    },
    isActionableItemHave({ status_id, sub_status_id, days, reviewed }) {
      const res = _.find(actionableItem, { status_id, sub_status_id });
      return (
        (isObject(res) &&
          !isEmptyObjectCheck(res) &&
          (res.days != undefined ? days < res.days : true) &&
          (res.reviewed != undefined ? reviewed === res.reviewed : true) &&
          (res.isJobSupplierFromAccessToken != undefined &&
          this.isJobSupplierFromAccessToken
            ? this.isJobSupplierFromAccessToken ===
              res.isJobSupplierFromAccessToken
            : true)) ||
        false
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.nowrap {
  white-space: nowrap;
}
table {
  border: 1px solid #e3e3e3;
}
thead {
  background-color: #fafafa;
}
td {
  color: #777777;
}
.clickable-row {
  cursor: pointer;
}
.clickable-row:hover {
  background-color: #f3f3f3;
}
.clickable-action {
  cursor: pointer;
}
.clickable-action:hover {
  text-decoration: underline;
}
</style>
