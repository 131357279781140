<template>
  <div class="candidate-alert">
    <CDataTable
      :striped="true"
      :bordered="true"
      :small="true"
      :items="getAccordionTransactions"
      :fields="accordionFields"
      :items-per-page="10"
      :sorter="false"
      :tableFilter="false"
      pagination
      class="p-2 accordion-table"
    >
      <template #no-items-view>
        <h5 class="text-center" :style="'color:'">
          No Data Found
          <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
        </h5>
      </template>
      <template #action="{ item, index }">
        <td class="py-2 align-middle">
          <div class="d-flex">
            <CButton
              class="mr-1 btn-primary btn-sm"
              v-show="
                (item.is_job_approval_enabled && index == 0) ||
                (item.is_editjob_enabled && index == 0 && disapproval == true)
              "
              @click="onEditClicked(item)"
            >
              Edit Job
            </CButton>
            <CButton
              name="mark-complete-btn"
              :id="`mark-complete-btn-${item.job_id}`"
              class="mr-1 btn-primary btn-sm"
              v-if="item.status_id == 29"
              v-show="
                (item.is_completed_enabled ||
                  item.is_interview_completed_enabled) &&
                index == 0
              "
              @click="onCompleteClicked(item)"
            >
              Publish Job
            </CButton>

            <CButton
              class="mr-1 btn-primary btn-sm"
              name="initiate-offer-btn"
              :id="`initiate-offer-cand-${item.candidate_uid}-job-${item.job_id}`"
              v-show="
                item.is_offer_completed_enabled &&
                item.is_interview_completed_enabled &&
                index == 0 &&
                !isCandidateSupplierFromAccessToken
              "
              @click="onCompleteClicked(item)"
            >
              Initiate Offer
            </CButton>
            <CButton
              class="mr-1 btn-primary btn-sm"
              v-if="item.status_id == 58"
              v-show="
                (item.offer_documents_status.length == 0 ||
                  item.is_interview_completed_enabled ||
                  item.is_onboard_requested) &&
                index == 0
              "
              @click="onDocumentsClicked(item)"
            >
              Additional Docs
            </CButton>
            <CButton
              class="mr-1 btn-primary btn-sm"
              v-if="item.status_id == 62"
              v-show="item.is_onboard_requested && index == 0"
              @click="onDocumentsClicked(item)"
            >
              Additional Docs
            </CButton>
            <CButton
              :name="`initiate-onboarding-cand-${item.candidate_uid}-job-${item.job_id}`"
              class="mr-1 btn-primary btn-sm"
              v-show="
                item.status_id == 58 &&
                item.sub_status_id == 60 &&
                index == 0 &&
                !isCandidateSupplierFromAccessToken &&
                !item.is_onboarded
              "
              @click="onCompleteOfferClicked(item)"
            >
              Initiate OnBoarding
            </CButton>
            <CButton
              class="mr-1 btn-primary btn-sm"
              v-show="
                item.status_id == 62 &&
                item.sub_status_id == 69 &&
                !isCandidateSupplierFromAccessToken &&
                item.onBoardAccepted &&
                index == 0
              "
              @click="onInitiateJoining(item)"
            >
              Initiate Joining
            </CButton>
            <CButton
              class="mr-1 btn-primary btn-sm"
              v-show="
                item.status_id == 58 &&
                item.sub_status_id == 60 &&
                index == 0 &&
                !isCandidateSupplierFromAccessToken &&
                item.is_onboarded &&
                (!item.all_onboard_document_submitted ||
                  !item.all_offer_document_submitted)
              "
              @click="onViewDocDetails(item)"
            >
              View Docs
            </CButton>
            <CButton
              class="mr-1 btn-primary btn-sm"
              v-show="
                item.status_id == 62 &&
                item.sub_status_id == 63 &&
                index == 0 &&
                !isCandidateSupplierFromAccessToken
              "
              @click="getJoinedDocuments(item)"
            >
              View Docs
            </CButton>
            <CDropdown
              placement="right-start"
              v-show="
                item.is_inactive_enabled &&
                index == 0 &&
                !isCandidateSupplierFromAccessToken
              "
            >
              <template #toggler>
                <button type="button" class="mr-1 btn-primary btn-sm">
                  Make Changes
                </button>
              </template>
              <a
                @click="updateJobStatus(item, 'inactive')"
                class="dropdown-item"
                style="cursor: pointer"
              >
                Make Inactive
              </a>
              <a
                @click="updateJobStatus(item, 'archive')"
                class="dropdown-item"
                style="cursor: pointer"
              >
                Make Archive
              </a>
              <a @click="onEdit()" class="dropdown-item" style="cursor: pointer"
                >Edit Job</a
              >
              <a
                @click="publishJob('publish')"
                class="dropdown-item"
                style="cursor: pointer"
              >
                {{
                  latestApprovalAudit
                    ? "Preview & Republish"
                    : "Preview & Publish"
                }}
              </a>
            </CDropdown>
            <CButton
              class="mr-1 btn-primary btn-sm"
              v-show="
                item.is_resubmit_enabled &&
                index == 0 &&
                !isCandidateSupplierFromAccessToken
              "
              @click="onResubmitCandidateClick(item)"
            >
              Re-Submit
            </CButton>
            <CButton
              class="mr-1 btn-primary btn-sm text-nowrap"
              v-show="
                item.is_reinstate_enabled &&
                index == 0 &&
                !isCandidateSupplierFromAccessToken
              "
              @click="onReinstateClicked(item)"
            >
              Re-Submit
            </CButton>
            <CButton
              class="mls btn-primary btn-sm"
              v-show="item.is_reinstate_enabled && index == 0"
              @click="onVaultstateClicked(item)"
            >
              Move to Vault
            </CButton>
            <CButton
              name="schedule-req-interview-btn"
              :id="`req-interview-cand-${item.candidate_uid}-job-${item.job_id}`"
              class="mr-1 btn-primary btn-sm"
              v-show="
                item.is_interview_req_enabled &&
                index == 0 &&
                !isCandidateSupplierFromAccessToken
              "
              @click="requestInterview(item)"
            >
              Request Interview
            </CButton>
            <CButton
              class="mr-1 btn-primary btn-sm"
              v-show="
                item.is_interview_slot_enabled &&
                index == 0 &&
                !isCandidateSupplierFromAccessToken
              "
              name="interview-schedule"
              :id="`interview-schedule-cand-${item.candidate_uid}-job-${item.job_id}`"
              @click="interviewSchedule(item)"
            >
              Interview Schedule
            </CButton>
            <CButton
              class="mr-1 btn-primary btn-sm"
              v-show="
                item.is_interview_slot_enabled &&
                index == 0 &&
                !isCandidateSupplierFromAccessToken
              "
              name="interview-mail"
              :id="`interview-schedule-mail-${item.candidate_uid}-job-${item.job_id}`"
              @click="SendMail(item)"
              ><i class="fa fa-envelope"></i>
            </CButton>
            <CButton
              color="primary"
              square
              size="sm"
              class="mr-1"
              v-show="
                item.is_job_reapproval_enabled &&
                index == 0 &&
                !isCandidateSupplierFromAccessToken &&
                item.days < 0
              "
              @click="onjobReapprovalstateClicked(item)"
            >
              Reapproval
            </CButton>
            <CButton
              v-show="showUpdateExpiry(item) && index == 0 && item.days < 0"
              color="primary"
              square
              size="sm"
              class="mr-1"
              @click="updateActionExpiry(item)"
            >
              Update Expiry
            </CButton>
            <CDropdown
              v-if="index === 0 && showThreeDots && item.threeDotActions.length"
              placement="bottom-start"
              class="px-1 d-flex align-items-center cursor-pointer ml-auto mr-0"
            >
              <template #toggler>
                <div class="mx-1 three-dots-lg">
                  <i class="fas fa-ellipsis-v"></i>
                </div>
              </template>
              <a
                class="dropdown-item cursor-action"
                v-for="data in item.threeDotActions"
                :key="data.action_id"
                @click="openPopup({ item, data })"
                :class="{
                  'positive-action': checkPositiveAction(data, item),
                }"
              >
                {{ data.label }}
              </a>
            </CDropdown>
          </div>
        </td>
      </template>
    </CDataTable>
    <CModal
      v-if="publishModal"
      id="Preview Job"
      centered:true
      :show.sync="publishModal"
      size="lg"
      color="primary"
    >
      <template #header-wrapper>
        <header class="modal-header" style="background: #dd3651; color: #fff">
          <h5
            class="modal-title px-3"
            style="font-size: 26px; font-weight: 300"
          >
            Preview Job
          </h5>
          <button
            @click="onCancelPublish"
            type="button"
            aria-label="Close"
            class="close"
          >
            ×
          </button>
        </header>
      </template>
      <template #body-wrapper>
        <JobPreview :jobDetails="getSelectedJobBenefitsAndAdvertDesc">
        </JobPreview>
        <ValidationObserver
          ref="action_modal"
          v-slot="{ onPublish }"
          v-if="
            getSelectedJobBoard.job_users &&
            getSelectedJobBoard.job_users.length
          "
        >
          <form id="action" @submit.prevent="onPublish()">
            <CRow class="m-2">
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Alert Set For</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <DatePicker
                        name="publish_request_expiry_date"
                        :value="approval_audit.publish_request_expiry_date"
                        @input="handleApprovalAudit"
                        :showFromTmw="true"
                        :error="errors[0]"
                        format="DD-MM-YYYY"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label name="lbl_urgent" class="col-lg-12 col-md-12"
                    >Is Urgent?</label
                  >
                  <CCol md="12">
                    <RadioButton
                      name="publish_request_is_urgent"
                      :value="approval_audit.publish_request_is_urgent"
                      :options="
                        options && options['boolean'] ? options['boolean'] : []
                      "
                      @change="handleApprovalAudit"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="12" class="mb-3">
                <CRow class="row">
                  <div class="col-lg-12 col-md-12 d-flex pb-2">
                    <div>
                      <label class="text-nowrap pr-3">Comment</label>
                    </div>
                    <div class="w-100" v-if="options['commentsOptions'].length">
                      <Select
                        name="customComments"
                        :value="approval_audit.customComments"
                        @input="handleChangeSelectCustom"
                        :options="
                          options && options['commentsOptions']
                            ? options['commentsOptions']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                      />
                    </div>
                  </div>
                  <!-- <label class="required col-lg-12 col-md-12">Comments</label> -->
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider v-slot="{ errors }">
                      <TextareaInput
                        name="publish_request_comments"
                        :value="approval_audit.publish_request_comments"
                        @input="handleApprovalAudit"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </template>
      <template #footer-wrapper>
        <div class="border-top d-flex justify-content-between py-3 flex-wrap">
          <div>
            <button
              name="cancel"
              class="
                rounded-0
                px-5
                btn-lg
                m-1
                mt-3
                float-left
                btn-outline-primary btn-lg
                mr-2
              "
              @click="onCancelPublish"
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              v-if="
                getSelectedJobBoard.job_users &&
                getSelectedJobBoard.job_users.length
              "
              name="publish_page"
              class="btn rounded-0 px-5 btn-primary btn-lg m-1 mt-3"
              @click="onPublish()"
              :disabled="!approval_audit.publish_request_comments"
            >
              {{ latestApprovalAudit ? "Republish Job" : "Publish Job" }}
            </button>
            <button
              v-else
              name="publish_page"
              class="btn rounded-0 px-5 btn-primary btn-lg m-1 mt-3"
              @click="onPublish()"
            >
              Publish Job
            </button>
          </div>
        </div>
      </template>
    </CModal>
    <CModal
      id="inActiveJobModal"
      title="NOTIFICATION"
      color="primary"
      centered:false
      @update:show="onUpdateJobStatus"
      :show.sync="updateStatusModal"
    >
      <p>
        Are you sure you want to
        {{ onChangeJobStatus == "inactive" ? "deactivate" : "archive" }} this
        job?
      </p>
    </CModal>
    <CModal
      id="warningModel"
      title="NOTIFICATION"
      color="danger"
      centered:false
      :show.sync="warningModal.isShowPopup"
    >
      <h6 class="text-center">{{ warningModal.content }}</h6>
      <template #footer-wrapper>
        <footer class="modal-footer">
          <button
            @click="warningModal.isShowPopup = false"
            class="btn btn-danger"
          >
            Ok
          </button>
        </footer>
      </template>
    </CModal>
    <CModal
      title="Update Expiry Date"
      centered:true
      color="primary"
      :show.sync="expiryUpdateModel.isShowPopup"
      v-if="expiryUpdateModel.isShowPopup"
    >
      <CRow class="row mb-3">
        <label class="required col-lg-12 col-md-12">Expiry Review Date</label>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <DatePicker
            name="expiry_review_date"
            :value="expiryUpdateModel.expiry_review_date"
            @change="handleDatePickerChange"
            :showFromTmw="true"
          />
        </div>
      </CRow>
      <CRow class="row mb-3">
        <CCol md="12">
          <CRow>
            <CCol md="6">
              <label class="text-primary">Expiry Review Date</label>
            </CCol>
            <CCol md="6">
              <label>: {{ actionExpiryDate(expiryUpdateModel.data) }}</label>
            </CCol>
          </CRow>
        </CCol>
        <CCol md="12">
          <CRow>
            <CCol md="6">
              <label class="text-primary"
                ><strong>Updated Review Date</strong></label
              >
            </CCol>
            <CCol md="6">
              <label>: {{ expiryUpdateModel.expiry_review_date }}</label>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <template #footer-wrapper>
        <footer class="modal-footer">
          <button
            @click="expiryUpdateModel.isShowPopup = false"
            class="btn btn-secondary"
          >
            Cancel
          </button>
          <button @click="onUpdateClicked" class="btn btn-primary">
            Confirm
          </button>
        </footer>
      </template>
    </CModal>
    <job-approval-comments
      :isShowPopup="jobApprovalComments.isShowPopup"
      :approval_audits="jobApprovalComments.approval_audits"
      :popupModalCallBack="jobApprovalCommentsCallBack"
    />
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import _ from "lodash";
import { getPositiveActionIds, CONSTANT as CONST } from "@/helpers/helper";
import { mapGetters, mapActions } from "vuex";
import DatePicker from "@/components/reusable/Fields/DatePicker.vue";
import TextareaInput from "@/components/reusable/Fields/TextareaInput.vue";
import JobPreview from "@/components/JobDetailPreview/JobDetailPreview.vue";
import Select from "@/components/reusable/Fields/Select.vue";
import JobApprovalComments from "@/components/JobListDetail/JobApprovalComments.vue";
import Modal from "@/components/reusable/Modal.vue";
import isJobSupplier from "@/mixins/isJobSupplier";
import JobListDetail from "@/components/JobListDetail/jobListDetail.vue";

export default {
  props: {
    accordionFields: {
      type: Array,
      default: () => [],
    },
    showThreeDots: {
      type: Boolean,
      default: false,
    },
  },
  extends: JobListDetail,
  mixins: [isJobSupplier],
  components: {
    DatePicker,
    TextareaInput,
    JobPreview,
    Select,
    JobApprovalComments,
    Modal,
  },

  data() {
    return {
      disapproval: false,
      updateStatusModal: false,
      onChangeJobStatus: "",
      publishModal: false,
      cloneModal: false,
      warningModal: {
        isShowPopup: false,
        content: "",
      },
      approval_audit: {},
      jobApprovalComments: {
        isShowPopup: false,
        approval_audits: {},
      },
      expiryUpdateModel: {
        isShowPopup: false,
        data: null,
        expiry_review_date: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getJobsForAccordion",
      "getOnboardDetailsforCandidate",
      "getOfferDetailsforCandidate",
      "isJobSupplierFromAccessToken",
      "isCandidateSupplierFromAccessToken",
      "getSelectedJobBenefitsAndAdvertDesc",
      "getSelectedJobBoard",
      "actionComments",
      "onboardDocumentActions",
    ]),
    getAccordionTransactions() {
      return (
        this.getJobsForAccordion
          .filter((val) => {
            // 67 - Review (Shortlist 1), 68 - Review (Shortlist 2)
            return ![67, 68].includes(val?.status_id);
          })
          .map((v) => ({
            ...v,
            initiated_date: this.formatDate(v?.initiated_date),
            display_status:
              v?.status_id === 29
                ? `${v?.status} [Approved]`
                : `${v?.status} ${
                    v?.sub_status ? "(" + v?.sub_status + ")" : ""
                  }`,
            comments:
              [28].includes(v?.status_id) && [74].includes(v?.sub_status_id)
                ? `${v?.comments} [This Job is Cancelled]`
                : v?.comments
                ? v?.comments
                : "--" || "--",
            requested_by: v?.initiated_by_user_name
              ? v?.initiated_by_user_name
              : "--",
            approved_by: v?.completed_by_user_name
              ? v?.completed_by_user_name
              : "--",
            // 73 - On Hold, 74 - Disapproved
            is_job_approval_enabled:
              [28].includes(v?.status_id) && [73].includes(v?.sub_status_id)
                ? true
                : false,
            is_editjob_enabled:
              [28].includes(v?.status_id) &&
              [72].includes(v?.sub_status_id) &&
              !v?.reviewed
                ? true
                : false,
            is_job_reapproval_enabled:
              [28].includes(v?.status_id) && [72].includes(v?.sub_status_id)
                ? true
                : false,
            is_resubmit_enabled: [44].includes(v?.sub_status_id) ? true : false,
            is_completed_enabled:
              [29, 52, 54].includes(v?.status_id) &&
              [null, 47].includes(v?.sub_status_id) &&
              !v?.reviewed &&
              !this.isJobSupplierFromAccessToken
                ? true
                : false,
            is_inactive_enabled:
              [28].includes(v?.status_id) &&
              [74].includes(v?.sub_status_id) &&
              !v?.reviewed
                ? true
                : false,
            is_interview_completed_enabled:
              [54].includes(v?.status_id) &&
              [57].includes(v?.sub_status_id) &&
              !v?.reviewed
                ? true
                : false,
            is_offer_completed_enabled:
              [54, 58].includes(v?.status_id) &&
              [57, 59, 60].includes(v?.sub_status_id) &&
              !v?.reviewed
                ? true
                : false,
            is_onboard_enabled:
              [62].includes(v?.status_id) &&
              [59].includes(v?.sub_status_id) &&
              v?.sub_status_id == null &&
              !v?.reviewed
                ? true
                : false,
            // is_completed_enabled: [28, 29].includes(v?.status_id) && [null, 74].includes(v?.sub_status_id) && !v?.reviewed? true : false,
            is_reinstate_enabled:
              [52].includes(v?.status_id) &&
              [47].includes(v?.sub_status_id) &&
              !v?.reviewed
                ? true
                : false,
            is_interview_req_enabled:
              [54].includes(v?.status_id) &&
              [50, 56].includes(v?.sub_status_id) &&
              !v?.reviewed
                ? true
                : false,
            is_interview_slot_enabled:
              [54].includes(v?.status_id) &&
              v?.sub_status_id == null &&
              !v?.reviewed
                ? true
                : false,
            slots:
              [54].includes(v?.status_id) && v?.sub_status_id == null
                ? this.getSlotDateAsString(v)
                : "--",
            selected_slot:
              [54].includes(v?.status_id) && [55].includes(v?.sub_status_id)
                ? this.getSelectedSlotDateAsString(v)
                : "--",
            candidate_document_ids:
              //  _(this.getOnboardDetailsforCandidate).map(({candidate_document_ids})=>candidate_document_ids?.length?true:false).value(),
              _(this.getOnboardDetailsforCandidate)
                .map(({ candidate_document_ids }) => candidate_document_ids)
                .value() || [],
            offer_documents_status:
              this.getOfferDetailsforCandidate.map(
                ({ candidate_uid }) => candidate_uid
              ) || [],
            expiry_review_date: v?.expiry_review_date,
            onBoardAccepted: this.checkAllOnBoardDocUpload(v),
            threeDotActions: this.showThreeDots ? this.getActions(v) || [] : [],
            is_onboard_requested:
              [62].includes(v?.status_id) && [69].includes(v?.sub_status_id),
          })) || []
      );
    },
    options() {
      return {
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        commentsOptions: this.actionComments || [],
      };
    },
    isAwaitingForApproval() {
      return (
        this.latestApprovalAudit?.status_id == 28 &&
        this.latestApprovalAudit.sub_status_id == 72
      );
    },
    latestApprovalAudit() {
      return (
        this.getSelectedJobBenefitsAndAdvertDesc?.approval_audits?.filter(
          (val) => !val.completed_by
        )[0] || null
      );
    },
    // get offer
    // Candidate Supplier and Job Supplier role check - for UK Main Agency
    // isCandidateSupplierFromAccessToken() {
    //   return this.isJobSupplierFromAccessToken || this.isCandidateSupplierFromAccessToken
    //     ? true
    //     : false
    // }
  },
  methods: {
    ...mapActions([
      "showToast",
      "modifyPublishJob",
      "update_job_expiryDate",
      "fetchSubmissionActionDetails",
      "fetchInterviewRequestAction",
      "fetchJobApprovalAction",
      "fetchJobsForDashboard",
      "fetchOfferActionDetails",
      "fetchOnBoardActionDetails",
      "fetchJoiningActionDetails",
      "getInterviewScheduledCandidateActionDetails",
    ]),
    checkPositiveAction(action, item) {
      const { action_id } = action;
      const { status_id, sub_status_id } = item;
      const actionIds =
        getPositiveActionIds({ status_id, sub_status_id }) || [];
      return actionIds.includes(action_id);
    },
    getActions(data) {
      return (
        data?.actions &&
        (data?.actions.map((val) => {
          return {
            label: val?.name,
            action_id: val?.id,
            directAction: val?.direct_action,
            status: val?.status,
            filter_id: val?.filter,
            tab_id: val?.tab,
            reason_id: val?.reason,
          };
        }) ||
          [])
      );
    },
    openPopup({ item, data }) {
      const jobDetails = item?.job;
      const candidateDetails = item?.candidate;
      let payload = {
        jobDetails: item,
        selectedAction: data,
      };
      this.$emit("actionModalEvent", payload);
    },
    checkAllOnBoardDocUpload(item) {
      if (item.status_id === 62 && item.sub_status_id === 69) {
        const recruiterSignReqAction = this.onboardDocumentActions
          .filter(
            (v) =>
              v?.action_required &&
              v?.actioned_by === "recruiter" &&
              !v?.previous_action_id
          )
          .map(({ document_action_id }) => document_action_id);
        const candidateReturnAction = this.onboardDocumentActions
          .filter(
            (v) => v?.actioned_by === "candidate" && v?.previous_action_id
          )
          .map(({ previous_action_id }) => previous_action_id);
        const res = recruiterSignReqAction.filter(
          (v) => !candidateReturnAction.includes(v)
        );
        return !res.length;
      }
      return null;
    },
    onEditClicked(item) {
      this.$emit("editJobClick", item);
    },
    onEdit() {
      this.$router.push({
        path: `/edit-job/${this.getSelectedJobBoard?.job_id}?step=0`,
      });
      this.disapproval = true;
    },
    handleChangeSelectCustom(name, value) {
      const comment = "publish_request_comments";
      const v = value ? value.id || value.code || value.label || value : null;
      Vue.set(this.approval_audit, comment, v);
      Vue.set(this.approval_audit, name, v);
      this.payload = {
        ...this.payload,
        [comment]: v,
      };
    },
    onCloneJob() {
      this.tooltipHide();
      this.updateJobId = this.getSelectedJobBoard?.job_id;
      this.cloneModal = true;
    },
    onShowCloneJob(value) {
      if (value) {
        if (this.updateJobId && this.cloneModal) {
          this.cloneJob({
            job_id: this.updateJobId,
          });
          this.updateJobId = null;
        }
      }
      this.cloneModal = false;
    },
    showUpdateExpiry(item) {
      return (
        (item?.status_id == 52 && item?.sub_status_id == 53) ||
        (item?.status_id == 54 && item?.sub_status_id == 51) ||
        (item?.status_id == 54 && item?.sub_status_id == 55) ||
        false
      );
    },
    updateActionExpiry(item) {
      this.expiryUpdateModel.data = item;
      this.expiryUpdateModel.expiry_review_date = null;
      this.expiryUpdateModel.isShowPopup = true;
    },
    actionExpiryDate(item) {
      return item?.expiry_date
        ? moment(item?.expiry_date).format("YYYY-MM-DD")
        : "--";
    },
    onUpdateClicked() {
      if (!this.expiryUpdateModel.expiry_review_date) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please select a date!",
        });
        return;
      }
      let payload = {
        action_detail_id: this.expiryUpdateModel.data.action_detail_id,
        expiry_review_date: this.expiryUpdateModel.expiry_review_date,
      };
      this.update_job_expiryDate(payload).then(() => {
        this.$emit("callForActions");
        // this.callForActions();
      });
      this.expiryUpdateModel.isShowPopup = false;
    },
    onUpdateJobStatus(e, value) {
      if (value.target.textContent == " OK ") {
        if (this.updateJobId && this.updateStatusModal) {
          this.onChangeActiveJobsStatus({
            job_id: this.updateJobId,
            status: this.onChangeJobStatus,
          });
          this.onChangeJobStatus = "";
        }
      }
      this.updateJobId = null;
      this.updateStatusModal = false;
    },
    onCancelPublish() {
      this.publishModal = false;
    },

    async onPublish() {
      if (this.getSelectedJobBoard.job_users?.length) {
        const isValid = await this.$refs.action_modal.validate();
        if (!isValid) {
          this.showToast({
            class: "bg-danger text-white",
            message: "please fill mandatory fields!",
          });
          return;
        }
      }
      let payload = {
        organisation_uid: this.getSelectedJobBoard?.organisation_uid,
        job_id: this.getSelectedJobBoard?.job_id,
        published: true,
        publish_request_is_urgent:
          this.approval_audit?.publish_request_is_urgent?.code ||
          this.approval_audit?.publish_request_is_urgent,
        publish_request_comments: this.approval_audit?.publish_request_comments,
        publish_request_expiry_date: moment(
          this.approval_audit?.publish_request_expiry_date
        )
          .add(5.5, "hours")
          .toISOString(),
      };
      if (
        moment(
          this.getSelectedJobBoard?.expiry_review_date,
          "YYYY-MM-DD"
        ).isBefore(moment())
      )
        payload = {
          ...payload,
          expiry_review_date: moment().add(30, "days").format("YYYY-MM-DD"),
        };
      this.modifyPublishJob(payload).then(() => {
        this.onCancelPublish();
        if (this.$router.currentRoute.params.jobStatus == "inactive") {
          this.$store.commit(
            "UPDATE_JOB_BOARD_BY_REMOVING_JOB",
            this.getSelectedJobBoard?.job_id
          );
        }
      });
    },
    handleApprovalAudit(name, value) {
      Vue.set(this.approval_audit, name, value);
    },
    publishJob(action) {
      if (action === "publish") {
        this.$refs.action_modal?.errors.clear;
        this.$refs.action_modal?.reset();
        let audits = [];
        if (this.getSelectedJobBenefitsAndAdvertDesc?.approval_audits?.length) {
          audits = this.getSelectedJobBenefitsAndAdvertDesc?.approval_audits
            ?.sort(
              (a, b) => new Date(b.initiated_date) - new Date(a.initiated_date)
            )
            .filter((val) => val.status_id == 28 && val.sub_status_id == 72)[0];
          if (moment(audits?.expiry_date, "YYYY-MM-DD").isBefore(moment()))
            audits.expiry_date = moment().add(5, "days");
        }
        this.approval_audit = {
          publish_request_comments: "",
          publish_request_is_urgent: audits?.is_urgent
            ? audits?.is_urgent
            : false,
          publish_request_expiry_date: audits?.expiry_date
            ? new Date(audits?.expiry_date)
            : new Date(moment().add(5, "days")),
        };
        this.publishModal = true;
      }
      this.disapproval = true;
    },

    jobApprovalCommentsCallBack() {
      this.jobApprovalComments.isShowPopup = false;
    },
    onCompleteClicked(item) {
      this.$emit("completeClicked", item);
    },
    onDocumentsClicked(item) {
      this.$emit("documentsClicked", item);
    },
    onCompleteOfferClicked(item) {
      this.$emit("completeOfferClicked", item);
    },
    onviewDocs(item) {
      this.$emit("viewDocument", item);
    },
    onViewDocDetails(item) {
      this.$emit("viewDocumentDetails", item);
    },
    onInitiateJoining(item) {
      this.$emit("iniateJoining", item);
    },
    updateJobStatus(item, status) {
      this.updateJobId = item.job_id;
      this.updateStatusModal = true;
      this.onChangeJobStatus = status;
      this.disapproval = true;
    },
    onResubmitCandidateClick(item) {
      this.$emit("resubmitCandidateClick", item);
    },
    onReinstateClicked(item) {
      this.$emit("reinstateClicked", item);
    },
    onVaultstateClicked(item) {
      this.$emit("vaultstateClicked", item);
    },
    onjobReapprovalstateClicked(item) {
      this.$emit("jobReapprovalstateClicked", item);
    },
    requestInterview(item) {
      let candidate_interviewers =
        item.job?.job_candidate_users?.filter(
          ({ customer_user }) => customer_user?.customer_user_type_id == 2
        ) || [];
      if (!candidate_interviewers.length) {
        let msg =
          "No Interviewer Found ! Please update a Candidate Interviewer for the job !!";
        this.showToast({
          class: "bg-danger text-white",
          message: msg,
        });
        return false;
      }
      this.$emit("requestInterviewClicked", item);
    },
    interviewSchedule(item) {
      this.$emit("interviewScheduleClicked", item);
    },
    SendMail(item) {
      this.$emit("openEmailModal", item);
    },
    formatDate(date) {
      if (date) return moment(date).format("DD MMM YYYY");
      return "--";
    },
    formatTime(time) {
      let date = new Date().toISOString().split("T")[0];
      if (time) return moment(date + " " + time).format("h:mm a");
      return "--";
    },
    getSlotDateAsString(action_detail) {
      let returnString = "";
      if (action_detail.available_slot1_date) {
        returnString += `${this.formatDate(
          action_detail?.available_slot1_date
        )} (${this.formatTime(
          action_detail.available_slot1_start
        )} - ${this.formatTime(action_detail.available_slot1_end)})`;
      }
      if (action_detail.available_slot2_date) {
        returnString += `, ${this.formatDate(
          action_detail?.available_slot2_date
        )} (${this.formatTime(
          action_detail.available_slot2_start
        )} - ${this.formatTime(action_detail.available_slot2_end)})`;
      }
      if (action_detail.available_slot3_date) {
        returnString += `, ${this.formatDate(
          action_detail?.available_slot3_date
        )} (${this.formatTime(
          action_detail.available_slot3_start
        )} - ${this.formatTime(action_detail.available_slot3_end)})`;
      }
      return returnString;
    },
    getSelectedSlotDateAsString(action_detail) {
      let returnString = "";
      if (action_detail.selected_slot_date) {
        returnString += `${this.formatDate(
          action_detail?.selected_slot_date
        )} (${this.formatTime(
          action_detail.selected_slot_start
        )} - ${this.formatTime(action_detail.selected_slot_end)})`;
      }
      return returnString;
    },
    handleDatePickerChange(name, value) {
      Vue.set(
        this.expiryUpdateModel,
        name,
        moment(new Date(value)).format("YYYY-MM-DD")
      );
    },
    getJoinedDocuments(item) {
      this.$emit("getJoiningDocuments", item);
    },
  },
};
</script>
