<template>
  <div>
    <div>
      <CRow class="mt-1">
        <CCol md="10">
          <CRow>
            <CCol md="12" style="margin-top: 5px; margin-left: 10px">
              <span style="color: red; font-weight: 500">
                For Request: {{ getScheduleSlotCount["interview_request"] }},
                For Schedule: {{ getScheduleSlotCount["interview_schedule"] }}
              </span>
            </CCol>
          </CRow>
        </CCol>
        <!-- <CCol md="2" class="mb-1">
                  <Select
                    name="scheduled"
                    :value="alert1['scheduled']"
                    @change="handleChangeSelect"
                    :options="
                      options && options['action_status']
                        ? options['action_status']
                        : []
                    "
                    :taggable="false"
                    :multiple="false"
                    :clearable="false"
                  />
                </CCol> -->
      </CRow>
    </div>
    <div class="documentScrolling document-table">
      <CDataTable
        :striped="false"
        :bordered="false"
        :small="true"
        :items="getInterviewScheduledActionDetails"
        :fields="candiateFields"
        :items-per-page="10"
        :sorter="false"
        :tableFilter="false"
        pagination
        class="p-2"
      >
        <template #candidate_name="{ item }">
          <td>
            <div class="d-flex text-nowrap">
              <span>
              <span
                class="circle mr-1"
                :style="`background-color: ${item.circle_color} !important`"
              ></span>
              <span
                style="color: #2678ce; font-weight: 600"
                class="clickable-action"
                @click="onCandidateClicked(item.candidate_id)"
              >
                <span>{{ item.candidate_name }}</span>
                <span class="mx-1">|</span>
                <span>{{ item.candidate_display_uid }}</span>
              </span>
              <span
                class="ml-2 cursor-pointer"
                v-c-tooltip="{
                  content: 'Click to View Candidate Details',
                  placement: 'left',
                  appendToBody: true,
                }"
                @click="openCandidateDetailsModal(item.candidate_id)"
                ><i class="fa-solid fa-circle-info text-primary"></i
              ></span>
              </span>
            </div>
          </td>
        </template>
        <!-- <template #candidate_id="{ item }">
          <td style="padding: 0">
            <tr>
              <td width="25%" style="border: none !important">
                <span
                  class="circle"
                  :style="`background-color: ${item.circle_color} !important`"
                ></span>
              </td>
              <td width="75%" style="border: none !important">
                <span
                  style="color: #2678ce; font-weight: 600"
                  class="clickable-action"
                  @click="onCandidateClicked(item.candidate_id)"
                  >{{ item.candidate_display_uid }}</span
                >
                <span
                  class="ml-2 cursor-pointer"
                  v-c-tooltip="{
                    content: 'Click to View Candidate Details',
                    placement: 'left',
                    appendToBody: true,
                  }"
                  @click="openCandidateDetailsModal(item.candidate_id)"
                  ><i class="fa-solid fa-circle-info text-primary"></i
                ></span>
              </td>
            </tr>
          </td>
        </template>
        <template #job_id="{ item }">
          <td>
            <span
              style="color: #2678ce; font-weight: 600"
              class="clickable-action"
              @click="onJobClicked(item.job_id)"
              >{{ item.job_display_uid }}</span
            >
            <span
              class="ml-1 cursor-pointer"
              v-c-tooltip="{
                content: 'Click to View Job Details',
                placement: 'left',
                appendToBody: true,
              }"
              @click="openJobDetailsModal(item.job_id)"
              ><i class="fa-solid fa-circle-info text-primary"></i
            ></span>
          </td>
        </template> -->
        <template #job_title="{ item }">
          <td>
            <div class="d-flex">
              <span>
                <span
                  style="color: #2678ce; font-weight: 600"
                  class="clickable-action"
                  @click="onJobClicked(item.job_id)"
                >
                  <span>{{ item.job_title }}</span>
                  <span class="mx-1">| </span>
                  <span>{{ item.job_display_uid }}</span>
                </span>
                <span
                  class="ml-1 cursor-pointer"
                  v-c-tooltip="{
                    content: 'Click to View Job Details',
                    placement: 'left',
                    appendToBody: true,
                  }"
                  @click="openJobDetailsModal(item.job_id)"
                  ><i class="fa-solid fa-circle-info text-primary"></i
                ></span>
              </span>
            </div>
            <span class="text-nowrap">{{
              item.job_organisation_name
                ? `[${item.job_organisation_name}]`
                : ""
            }}</span>
          </td>
        </template>
        <template #no-items-view>
          <h5 class="text-center" :style="'color:'">
            No Data Found
            <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
          </h5>
        </template>
        <template #action="{ item, index }">
          <td class="py-2">
            <div class="d-flex">
              <CButton
                v-if="collapseIndex === index"
                name="schedule-collapse-transaction"
                :id="`schedule-collapse-trans-cand-${item.candidate_uid}-job-${item.job_id}`"
                square
                class="mr-1 btn-outline-primary btn-sm"
                @click="onToggleClick(item, index)"
                v-c-tooltip="{
                  content: 'Collapse all Transactions!',
                  placement: 'left',
                  appendToBody: true,
                }"
              >
                <i class="fa fa-minus"></i>
              </CButton>
              <CButton
                v-if="collapseIndex !== index"
                name="schedule-list-transaction"
                :id="`schedule-list-trans-cand-${item.candidate_uid}-job-${item.job_id}`"
                square
                class="mr-1 btn-outline-primary btn-sm position-relative"
                @click="onToggleClick(item, index)"
                v-c-tooltip="{
                  content: 'List all Transactions!',
                  placement: 'left',
                  appendToBody: true,
                }"
              >
                <i class="fa fa-plus"></i>
                <span
                  class="dot-badge"
                  v-if="isActionableItemHave(item) || item.days < 0"
                ></span>
              </CButton>
            </div>
          </td>
        </template>
        <template #details="{ item, index }">
          <CCollapse
            v-if="collapseIndex === index"
            :show="collapseIndex === index"
            :duration="collapseDuration"
            class="p-2 border selected-row"
          >
            <h5 class="p-2 text-primary">
              Job ID: {{ item.job_display_uid }}
              <span style="color: black">|</span> Job Title:
              {{ item.job_title }}
              <span style="color: black">|</span> Transactions:
              {{ getDashboardTabCount.accordion }}
            </h5>
            <CSpinner
              v-if="isTransactionFetch"
              color="primary"
              class="mx-5"
              style="width: 2rem; height: 2rem"
            />
            <AccordionTransaction
              v-else
              :accordionFields="jobAccordionScheduledFields"
              @requestInterviewClicked="$emit('requestInterviewClicked', item)"
              @interviewScheduleClicked="
                $emit('interviewScheduleClicked', item)
              "
              :showUpdateExpiry="true"
              @openEmailModal="$emit('openEmailModal', item)"
              :showThreeDots="true"
              @actionModalEvent="actionModalEvent"
            />
          </CCollapse>
        </template>
        <template #docs="{ item }">
          <td class="py-2">
            <a
              class="btn d-inline"
              v-c-tooltip="{ content: 'Documents', placement: 'left' }"
            >
              <img               
                :src="checklistGreen"
                width="25"
                height="25"
                @click="openCheckList(item)"
              />
            </a>
          </td>
        </template>
      </CDataTable>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import {
  getRAGColorsCircle,
  actionableItem,
  isObject,
  isEmptyObjectCheck,
} from "@/helpers/helper";

import AccordionTransaction from "@/containers/Dashboard/RecruiterDashBoard/JobCandidateStatus/AccordionTransaction";
import checklistGreen from "/public/img/checklist-green.svg";
import checklistRed from "/public/img/checklist-red.svg";

export default {
  props: {
    collapseIndex: {
      type: Number,
      default: () => null,
    },
    collapseDuration: {
      type: Number,
      default: () => 0,
    },
    isTransactionFetch: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    Select,
    AccordionTransaction,
  },
  data() {
    return {
      alert1: {
        job_approval: { code: "pending", label: "Pending" },
        submission: { code: "pending", label: "Pending" },
        interview: { code: "pending", label: "Pending" },
        scheduled: { code: "pending", label: "Pending" },
      },
      jobAccordionScheduledFields: [
        { key: "initiated_date", label: "Date", _style: "width: 15%" },
        { key: "requested_by", label: "Name", _style: "width: 15%" },
        // { key: "approved_by", label: "Approval By", _style: "width: 20%" },
        { key: "display_status", label: "Status", _style: "width: 15%" },
        { key: "comments", label: "Comments", _style: "width: 40%" },
        { key: "action", label: "Action/Slot(s)", _style: "width: 25%" },
      ],
      candiateFields: [
        // { label: "Candidate ID", key: "candidate_id", _style: "width: 8%" },
        {
          label: "Candidate",
          key: "candidate_name",
          _style: "width: 14%",
        },
        // { label: "Job ID", key: "job_id", _style: "width: 5%" },
        { label: "Job", key: "job_title", _style: "width: 18%" },
        // { key: "requested_by", label: "Requested By", _style: "width: 15%" },
        // {
        //   label: "Initiated Date",
        //   key: "initiated_date",
        //   _style: "width: 10%",
        // },
        {
          key: "days_for_approval",
          label: "Days for Approval",
          _style: "width: 5%",
        },
        {
          label: "Joining Date",
          key: "joining_date",
          _style: "width: 10%",
        },
        { key: "approved_by", label: "Approval By", _style: "width: 9%" },
        { label: "Status", key: "status", _style: "width: 8%" },
        { label: "Comments", key: "comments", _style: "width: 18%" },
        { key: "action", label: "Action", _style: "width:5%" },
        { key: "docs", label: "Check List", _style: "width:5%" },
      ],
      checklistGreen,
      checklistRed,
    };
  },
  computed: {
    ...mapGetters([
      "getInterviewRequestAction",
      "getDashboardTabCount",
      "isCandidateSupplierFromAccessToken",
    ]),
    options() {
      return {
        action_status: [
          { code: "pending", label: "Pending" },
          { code: "completed", label: "Completed" },
        ],
      };
    },
    getInterviewScheduledActionDetails() {
      return (
        this.getInterviewRequestAction
          .filter((val) => {
            const isNull =
              this.alert1["scheduled"]?.code === "pending" ? true : false;
            // if (isNull)
            // // 50 - Approved for Interview, 51 - Interview Requested
            //   return [50, 51].includes(val?.sub_status_id);
            // else
            //   return [54].includes(val?.status_id) && val?.sub_status_id == null;
            if (isNull) return !val?.reviewed;
            else return val?.reviewed;
          })
          .map((v) => ({
            ...v,
            candidate_id: v?.candidate_uid,
            candidate_display_uid: v?.candidate_display_uid,
            candidate_name: v?.candidate?.full_name,
            job_title: v?.job?.job_title,
            job_organisation_name: v?.job?.organisation_name,
            initiated_date: this.formatDate(v?.initiated_date),
            expiry_date: this.formatDate(v?.expiry_date),
            status: [50, 51].includes(v?.sub_status_id)
              ? `${v?.status} (${v?.sub_status})`
              : v?.available_slot1_date
              ? "Interview Requested"
              : "--",
            comments: v?.comments || "--",
            // days_for_approval: [50, 51].includes(v?.sub_status_id) ? `${getDaysFromDate(v?.expiry_date)} Day(s)` : "--",
            days_for_approval: v?.display_text_days || "--",
            requested_by: v?.initiated_by_user_name
              ? v?.initiated_by_user_name
              : "--",
            approved_by: [50, 51, null].includes(v?.sub_status_id)
              ? v?.initiated_by_user_name
                ? v?.initiated_by_user_name
                : "--"
              : "--",
            // days: [50, 51].includes(v?.sub_status_id) ? getDaysFromDate(v?.expiry_date) : getDaysFromDate(v?.initiated_date),
            // _classes: [50, 51].includes(v?.sub_status_id) ? this.getRAGColors(v?.days) : "",
            circle_color: [50, 51].includes(v?.sub_status_id)
              ? getRAGColorsCircle(v?.days)
              : "",
            joining_date: this.formatDate(v?.joining_date),
          }))
          .sort((a, b) => {
            if ([50, 51].includes(a?.sub_status_id) && b.days >= 0)
              return a.days > b.days ? 1 : b.days > a.days ? -1 : 0;
            return a.action_detail_id - b.action_detail_id;
          }) || []
      );
    },
    getScheduleSlotCount() {
      let interview_request = 0,
        interview_schedule = 0;
      this.getInterviewRequestAction
        .filter((val) => {
          const isNull =
            this.alert1["submission"]?.code === "pending" ? true : false;
          if (isNull)
            return [50, null].includes(val?.sub_status_id) && !val.reviewed;
          else return [50, 51].includes(val?.sub_status_id) && val.reviewed;
        })
        .map((v) => ({
          interview_request:
            v?.sub_status_id == 50
              ? (interview_request += 1)
              : (interview_request += 0),
          interview_schedule:
            v?.sub_status_id == null
              ? (interview_schedule += 1)
              : (interview_schedule += 0),
        }));
      return { interview_request, interview_schedule };
    },
  },
  mounted() {
    this.callForActions();
  },
  methods: {
    ...mapActions(["fetchInterviewRequestAction"]),
    // getPreOfferDocuments(item) {
    //   this.$emit("preOfferViewDocumentDetails", item);
    // },
    openCheckList(item){
      this.$emit("openCheckList",item);
    },
    actionModalEvent(data) {
      this.$emit("actionModalEvent", data);
    },
    openCandidateDetailsModal(candidate_uid) {
      this.$emit("openCandidateInfoModal", candidate_uid);
    },
    openJobDetailsModal(job_id) {
      this.$emit("openJobInfoModal", job_id);
    },
    callForActions() {
      this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
      let appendAction = [];
      appendAction = [...appendAction, this.fetchInterviewRequestAction()];

      Promise.all(appendAction).then((res) => {
        this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
          schedule: this.getInterviewScheduledActionDetails.length,
        });
      });
    },
    onToggleClick(item, index) {
      this.$emit("toggleClick", item, index, true, "scheduled");
    },
    onJobClicked(job_id) {
      this.$emit("toJob", job_id);
    },
    onCandidateClicked(candidate_id) {
      this.$emit("toCandidate", candidate_id);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.alert1, name, value);
      this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
      this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
        schedule: this.getInterviewScheduledActionDetails.length,
      });
    },
    formatDate(date) {
      if (date) return moment(date).format("DD MMM YYYY");
      return "--";
    },
    isActionableItemHave({ status_id, sub_status_id }) {
      if (this.isCandidateSupplierFromAccessToken) return false;
      const res = _.find(actionableItem, { status_id, sub_status_id });
      return (isObject(res) && !isEmptyObjectCheck(res)) || false;
    },
  },
};
</script>
<style lang="scss" scoped>
.nowrap {
  white-space: nowrap;
}
table {
  border: 1px solid #e3e3e3;
}
thead {
  background-color: #fafafa;
}
td {
  color: #777777;
}
.clickable-row {
  cursor: pointer;
}
.clickable-row:hover {
  background-color: #f3f3f3;
}
.clickable-action {
  cursor: pointer;
}
.clickable-action:hover {
  text-decoration: underline;
}
</style>